<template>
    <div class="main_business_en_div">
        <div>
            <div class="business_banner">
                <img :src="imgSrc[0]"/>
            </div>
            <div class="business_title_div allContentWidth">
                <div class="tab_div">
                    <ul class="tab-title-en">
                        <li v-for="item in tabArr" :key="item.id" :class="{active:curActive==item.id}" @click="tabClickFun(item.id)">{{item.name}}</li>
                    </ul>
                </div>
                <div class="business_title_div">
                    <div class="business_title_img">
                        <!-- <img :src="imgSrc[1]"/> -->
                        <p>Main Business</p>
                        <div class="line_div_box"></div>
                    </div>
                </div>
                <div class="main_tab_content">
                    <div class="business_con_div" v-show="showLeftFlag">
                        <div class="business_text_div">
                            <div class="business_text_all_box">
                                <div class="main_title">
                                    <span>{{contentObj.title}}</span>
                                    <!-- <div class="white_line_div_one"></div> -->
                                </div>
                                <div class="main_section_all_box">
                                    <p v-for="c in contentObj.contentText" :key="c.id">{{c.texts}}</p>
                                </div>
                                <div class="bottom_two_module">
                                    <div class="left_module_box">
                                        <div class="white_line_div_two"></div>
                                        <p class="left_title">{{contentObj.bottom_content_title}}</p>
                                        <div class="download_img_icon_box" v-if="[5,6].indexOf(curActive)!==-1">
                                            <div class="download_img_icon">
                                                <img :src="imgSrc[3]"/>
                                            </div>
                                            <span @click="downloadFun(curActive)">PDF Introduction</span>
                                        </div>
                                    </div>
                                    <div class="right_module_box">
                                        <p  class="right_introduce" v-for="ul in contentObj.div_ul_obj" :key="ul.id">
                                            <b class="circle_b"></b>
                                            <span>{{ul.value}}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="business_img_div">
                            <img :src="contentObj.right_img"/>
                        </div>
                    </div>
                    <div class="business_con_div" v-show="showRightFlag">
                        <div class="business_img_div">
                            <img :src="contentObj.right_img"/>
                        </div>
                        <div class="business_text_div">
                            <div class="business_text_all_box">
                                <div class="main_title">
                                    <span>{{contentObj.title}}</span>
                                    <!-- <div class="white_line_div_one"></div> -->
                                </div>
                                <div class="main_section_all_box">
                                    <p v-for="c in contentObj.contentText" :key="c.id">{{c.texts}}</p>
                                </div>
                                <div class="bottom_two_module">
                                    <div class="left_module_box">
                                        <div class="white_line_div_two"></div>
                                        <p class="left_title">{{contentObj.bottom_content_title}}</p>
                                        <div class="download_img_icon_box" v-if="[5,6].indexOf(curActive)!==-1">
                                            <div class="download_img_icon">
                                                <img :src="imgSrc[3]"/>
                                            </div>
                                            <span @click="downloadFun(curActive)">PDF Introduction</span>
                                        </div>
                                    </div>
                                    <div class="right_module_box">
                                        <p  class="right_introduce" v-for="ul in contentObj.div_ul_obj" :key="ul.id">
                                            <b class="circle_b"></b>
                                            <span>{{ul.value}}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import utils from '@/libs/utils';
export default {
    components: {},
    computed: {},
    mounted() {
        this.params();
    },
    watch: {
        '$route' (to, from) {
            if(to.query.tabActive != from.query.tabActive){
                this.curActive = Number(to.query.tabActive);
                this.tabClickFun(this.curActive);
            }
        }
    },
    methods: {
        params(){
            var query = location.search.substr(1);
            query = query.split('&')
            var params = {};
            for (let i = 0; i < query.length; i++) {
                let q = query[i].split('=')
                if (q.length === 2) {
                    params[q[0]] = q[1]
                }
            }
            if((JSON.stringify(params) === '{}')===false){ //判断是否为空对象
                this.curActive = Number(params.tabActive);
            }else{
                this.curActive = 1;
            }
            this.tabClickFun(this.curActive);
        },
        tabClickFun(id){
            this.curActive = id;
            this.contentAll.forEach((item)=>{
                // console.log(item)
                if(id === item.key){
                   this.contentObj = item;
                }
            })
            if([1,3,5].indexOf(id)!==-1){
                this.showLeftFlag = true;
                this.showRightFlag = false;
            }else{
                this.showRightFlag = true;
                this.showLeftFlag = false;
            }
        },
        downloadFun(id){
            id === 5 ? window.open('https://wetec-constrain.cdn.bcebos.com/material/dzhi/ceiv.pdf','_blank') :window.open('https://wetec-constrain.cdn.bcebos.com/material/dzhi/%E9%A1%B9%E7%9B%AE%E7%89%A9%E6%B5%81.pdf','_blank')
        }
    },
    data() {
        var imgSrc = [];
        imgSrc.push(require('../../assets/img/business/business_banner.png'));//(0)
        imgSrc.push(require('../../assets/img/business/hy_business.png'));//(1)
        imgSrc.push(require('../../assets/img/business/download.png'));//(2)
        imgSrc.push(require('../../assets/img/business/look_icon.png'));//(3)
        return {
            imgSrc: imgSrc,
            curActive: 1,
            tabArr:[
                {id: 1,name: 'Ocean Freight'},//海运
                {id: 2,name: 'Air Freight'},//空运
                {id: 3,name: 'Contract Logistics'},//合约物流
                {id: 4,name: 'Inter-Continents Rail'},//中欧铁路
                {id: 5,name: 'Pharmaceutical logistics'},//医药物流
                {id: 6,name: 'Project Cargo'},//项目物流
            ],
            showLeftFlag:false,
            showRightFlag:false,
            contentObj:{},
            contentAll:[
                {
                    key:1,
                    title: 'Ocean Freight',//海运
                    contentText:[
                        {id: 1,texts:'With more than 20 years of NVOCC freight forwarding experience, Eastrong has established a strong transportation network with many well-known ocean carriers. Every year, we transport over 100,000TEU of cargo to our customers from every corner of the world.'},
                        {id: 2, texts: 'The route to Australia & New Zealand is our core business, and with years of experience, we have achieved a high-level performance and efficiency in this trade. Based on this knowledge, in recent years we have successfully expanded our routes to America, Europe and Southeast Asia.'},
                        {id: 3,texts: 'We provide our clients with flexible ocean shipping solutions, and an advanced freight tracking system, to ensure that their cargo arrives timely, and safely at its destination.'}
                    ],
                    bottom_content_title: 'Main Services',
                    div_ul_obj: [
                        {id: 1, value: 'Booking'},
                        {id: 2, value: 'FCL/LCL'},
                        {id: 3, value: 'DG Cargo Handling'},
                        {id: 4, value: 'Consolidation'},
                        {id: 5, value: 'SOC & Charter Service'},
                        {id: 6, value: 'Customs Brokerage'},
                        {id: 7, value: 'Advanced Shipping System – CargoWise'},
                        {id: 8, value: 'project logistics'},
                    ],
                    right_img: require('../../assets/img/business/hy1_business.png')
                },
                {
                    key:2,
                    title: 'Air Freight',//空运
                    contentText:[
                        {id: 1,texts:'Eastrong believes in maintaining high-quality, long-term partnerships with the leading international airlines. We can create customized air transportation solutions and provide our clients with Air Import and Export services at key Chinese locations such as Shanghai, Hong Kong, Shenzhen, Guangzhou, Xiamen, Fuzhou, and Beijing, amongst others. Using our strong partner network during the pandemic, we maintained our high performance to deliver our customers’ goods in a variety of industries, to the stipulated destinations in a timely and safe manner.  Eastrong is a CEIV Pharma certified forwarder and is able to manage the transportation services of all pharmaceutical products to the highest standards worldwide.'},
                    ],
                    bottom_content_title: 'Main Services',
                    div_ul_obj: [
                        {id: 1, value: 'Year-Round Block Space Agreement'},
                        {id: 2, value: 'DG Cargo Operation'},
                        {id: 3, value: 'Pharma & Temperature Controlled Goods Operation'},
                        {id: 4, value: 'E-Commerce'},
                        {id: 5, value: 'Hand Carry Service'},
                        {id: 6, value: 'Cargo Track & Trace with 24/7 accessibility'},
                        {id: 7, value: 'Charter Service'},
                    ],
                    right_img: require('../../assets/img/business/ky_business.png')
                },
                {
                    key:3,
                    title: 'Contract Logistics',//合约物流
                    contentText:[
                        {id: 1,texts:'Eastrong can provide a complete integrated warehousing and distribution management solution. Our industry leading supply chain innovations are specifically designed to address our clients many supply chain challenges such as, excess inventory at destination caused by high volume orders, increasing transportation and handling costs, amongst others. '},
                        {id: 2, texts:'In 2018, we opened a new bonded warehouse of 10,000 square meters in Shanghai Yangshan Special Free Trade Zone. We have implemented an industry leading warehouse management system - Manhattan，which is a highly regarded professional WMS that automates inventory management, sorting, storage, picking, packing and other warehouse processes. This system provides our customers with real time data access and performance reporting, tailored to the unique processes required by the individual customer.'}
                    ],
                    bottom_content_title: 'Main Services',
                    div_ul_obj: [
                        {id: 1, value: 'Multiple Country / Vendor Consolidation'},
                        {id: 2, value: 'Value-Added-Service ( Pick & Pack, Labelling, Repackage, Assembling )'},
                        {id: 3, value: 'Vendor Management'},
                        {id: 4, value: 'System Integration'},
                        {id: 5, value: 'Local Transportation'},
                        {id: 6, value: 'Customs Brokerage'},
                    ],
                    right_img: require('../../assets/img/business/htwl1_business.png')
                },
                {
                    key:4,
                    title: 'Inter-Continents Rail',//中欧铁路
                    contentText:[
                        {id: 1,texts:'Leveraging the international Belt and Road initiative, Eastrong can provide our clients with customized international railway import and export transportation solutions using multiple routes. '},
                        {id: 2, texts:'In 2021, Eastrong set up a wholly owned subsidiary company, SGIT, to specialize in rail services and the company has delivered over 1,600TEU through railway services for our customers in various industries.'}
                    ],
                    bottom_content_title: 'Main Routes',
                    div_ul_obj: [
                        {id: 1, value: 'China – Europe Lane'},
                        {id: 2, value: 'China – Russia Lane'},
                        {id: 3, value: 'China – Central Asia Lane'},
                        {id: 4, value: 'China – Vietnam Lane'},
                        {id: 5, value: 'China – Laos Lane'},
                    ],
                    right_img: require('../../assets/img/business/zotl1_business.png')
                },
                {
                    key:5,
                    title: 'Pharmaceutical logistics',//医药物流
                    contentText:[
                        {id: 1,texts:'Due to the spread of the global pandemic,  the demand for transportation of pharmaceutical products by government agencies and charitable organizations in various countries has increased sharply. In March 2022, following a series of extensive audits, Eastrong was awarded the IATA global authoritative pharmaceutical logistics qualification certification - CEIV Pharma. To achieve this, we refined and standardized the process of cold chain management and transportation in accordance with international standards and have implemented strict quality control in all processes of pharmaceutical cargo transportation.  Customized medical transportation solutions can be provided according to unique customer requirements, to ensure cargo can be delivered in a timely and safe manner.'},
                    ],
                    bottom_content_title: 'Main Services',
                    div_ul_obj: [
                        {id: 1, value: 'Temperature Controlled Warehousing(GDP Standard)'},
                        {id: 2, value: 'Temperature Controlled Vehicles'},
                        {id: 3, value: 'Air Regular Charter Service'},
                        {id: 4, value: 'Dry ice, Blue ice and Cooling box consumables'},
                    ],
                    right_img: require('../../assets/img/business/yywl1_business.png')
                },
                {
                    key:6,
                    title: 'Project Cargo',//项目物流
                    contentText:[
                        {id: 1,texts:'Eastrong has extensive project cargo experience and has specialized in break-bulk, RORO, heavy lifts and integrated projects.'},
                        {id: 2, texts:'We manage various types of industrial project cargoes including break-bulk and oversized project shipments such as steel products, air-bridge structures, yachts, shipyard modules and transformer, over the past years.'}
                    ],
                    bottom_content_title: 'Main Services',
                    div_ul_obj: [
                        {id: 1, value: 'Feasibility studies'},
                        {id: 2, value: 'Onsite inspections, both at place of departure and arrival'},
                        {id: 3, value: 'Multimodal services including Air, Ocean, Ground and Rail transport'},
                        {id: 4, value: 'Break bulk, RORO, heavy-lift, OOG, special equipment (flat rack, open top, platform containers) and vessel chartering service'},
                        {id: 5, value: 'Air-suspension / Temperature-controlled trucking and WGS (White Glove Service).'},
                    ],
                    right_img: require('../../assets/img/business/xmwl_business.png')
                },
            ],
        }
    }
}
</script>
<style lang="less">
.main_business_en_div{
    .business_banner{
        width:100%;
        img{
            width:100%;
            height:100%;
        }
    }
    .business_title_div{
        //tab页
        .tab_div{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 107px;
            margin-top: -60px;
            .tab-title-en{
                display: flex;
                align-items: center;
                height: 107px;
                background: #fff;
                box-shadow:  0px 1px 17px 1px rgba(0,0,0,0.1000);
                li{
                    text-align: center;
                    margin: 0 36px;
                    cursor: pointer;
                    font-size: 18px;
                    color: #232931;
                    position: relative;
                }
                li:hover::after{
                    width: 100%;
                }
                li::after{
                    transition: all 0.5s;
                    position: absolute;
                    content: "";
                    bottom: 0px;
                    left: 0px;
                    width: 0px;
                    height: 3px;
                    background: #2200ba;
                    // top: 40px;
                }
            }
            .tab-title-en .active {
                font-weight: 700;
                &::after{
                    width: 100%;
                }
			}
        }
        //标题
        .business_title_div{
            display: flex;
            justify-content: center;
            .business_title_img{
                margin: 50px 0 30px 0;
                font-size: 30px;
                font-weight: 700;
                .line_div_box{
                    background: #2200ba;
                    width: 30px;
                    height: 4px;
                    margin: auto;
                }
                img{
                    width:100%;
                    height:100%;
                }
            }
        }
        //tab页内容
        .main_tab_content{
            background: #2C2E32;
            margin: 40px 0 60px 0;
            .business_con_div{
                display: flex;
                // justify-content: space-between;
                .business_text_div{
                    display: flex;
                    justify-content: center;
                    padding: 50px 0;
                    .business_text_all_box{
                        width: 90%;
                        .main_title{
                            font-size: 44px;
                            font-weight: bold;
                            color: #FFFFFF;
                            // text-align: center;
                            .white_line_div_one{ //公共白条
                                width:60px;
                                height: 3px;
                                background: #fff;
                                display: inline-block;
                                margin:0 0 0 15px;
                            }
                        }
                        .main_section_all_box{
                            margin: 30px 0;
                            p{
                                font-size: 14px;
                                color: #FFFFFF;
                                line-height: 40px;
                            }
                        }
                        .bottom_two_module{
                            color: #FFFFFF;
                            display: flex;
                            // justify-content: space-between;
                            // padding-right: 50px;
                            .left_module_box{
                                line-height:50px;
                                padding-right: 50px;
                                .white_line_div_two{
                                    width:45px;
                                    height: 3px;
                                    background: #fff;
                                }
                                .left_title{
                                    font-size: 20px;
                                }
                                .download_img_icon_box{
                                    display: flex;
                                    align-items: center;
                                    .download_img_icon{
                                        width: 28px;
                                        height: 28px;
                                        margin-right: 5px;
                                        img{
                                            width:100%;
                                            height:100%;
                                        }
                                    }
                                    span{
                                        cursor: pointer;
                                    }
                                }
                            }
                            .right_module_box{
                                width: 65%;
                                .right_introduce{
                                    line-height: 30px;
                                    display: flex;
                                    // align-items:center;
                                    .circle_b{
                                        width: 6px;
                                        height: 6px;
                                        background: #fff;
                                        display: inline-block;
                                        border-radius: 3px;
                                        margin-right: 8px;
                                        flex-shrink: 0;
                                        margin: 11px 10px 0 0;
                                    }
                                }
                            }
                        }
                    }
                }
                .business_img_div{
                    // width:629px;
                    // height:766px;
                    img{
                        font-size: 0;
                        display: block;
                        width:500px;
                        height:100%;
                        // width:100%;
                        // height:100%;
                    }
                }
            }
        }
    }
}
@media screen and (max-width:900px){
    .main_business_en_div{
        .business_title_div{
            //tab页
            .tab_div{
                margin-top: 0;
                height: 70px;
                .tab-title-en{
                    height: 70px;
                    overflow-x: auto;
                    max-width: 100%;
                    li{
                        margin: 0 20px;
                        font-size: 12px;
                    }
                }
            }
            //tab页内容
            .main_tab_content{
                .business_con_div{
                    flex-direction: column;
                    .business_text_div{
                        .bottom_two_module{
                            flex-direction: column;
                            .right_module_box{
                                width:100%!important;
                            }
                        }
                    }
                    .business_img_div{
                        width:100%;
                        height:100%;
                        img{
                           width: 100%;
                           height: 100%;
                        }
                    }
                }
            }

        }
    }
}
</style>
